<template>
    <div class="wisldtemplate">
        <myhead :param="param" />
        <section>
            <div class="bannerwisld">
                <div style="height:280px; "></div>
                <div style="font-size: 44px;font-weight: bold;color: #ffffff;"></div>

            </div>
        </section>
        <section class="body1200" style="margin-top: 90px;">
            <div class="txt18">智慧路灯应用方案</div>
            <div class="txt60" style="margin-top: 30px">智慧路灯</div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                智慧路灯是指通过应用先进、高效、可靠的电力线载波通信技术和无线GPRS/CDMA通信技术等，实现对路灯的远程集中控制与管理的路灯，智慧路灯具有根据车流量自动调节亮度、远程照明控制、故障主动报警、灯具线缆防盗、远程抄表等功能，能够大幅节省电力资源，提升公共照明管理水平，节省维护成本。
            </div>
        </section>
        <section class="body1200" style="margin-top: 160px;">
            <div  class="txt42">
                整体解决方案述
            </div>

            <div class="txt18" style="margin-top: 30px;width:860px;">
                智慧路灯是智慧城市的重要组成部分。它应用城市传感器、电力线载波/ZIGBEE通信技术和无线GPRS/CDMA通信技术等，将城市中的路灯串联起来，形成物联网，实现对路灯的远程集中控制与管理，具有根据车流量、时间、天气情况等条件设定方案自动调节亮度、远程照明控制、故障主动报警、灯具线缆防盗、远程抄表等功能。

            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;text-align: center;font-size: 24px;">
                <div class="hcell"><div><img :src="wisld_fa1"> </div> </div>
                <div class="hcell"><div><img :src="wisld_fa2"> </div> </div>
                <div class="hcell"><div><img :src="wisld_fa3"> </div> </div>
            </div>
        </section>
        <section class="body1200" style="margin-top: 160px;">
            <div  class="txt42">
                功能展示
            </div>
            <div class="wisld_jg" style="margin-top: 60px;">
            </div>
        </section>
        <section class="body1200" style="margin-top: 160px;">
            <div  class="txt42">
                功能阐述
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;width: 1120px;">
                <div class="hcell"><div class="hec_gn htable ">
                    <div class="hcell"><img :src="wisld_gn1"></div>
                    <div class="hcell" style="width: 342px;">
                        <div class="gnhead">光伏发电</div>
                        <div class="gntxt">以太阳能光电转换提供电能，充分利用清洁能源、也可接入市电。</div>
                    </div>
                </div></div>
                <div class="hcell"><div class="hec_gn htable ">
                    <div class="hcell"><img :src="wisld_gn2"></div>
                    <div class="hcell" style="width: 342px;">
                        <div class="gnhead">充电桩</div>
                        <div class="gntxt">方便新能源汽车、电瓶车充电，附带5V USB接口在行人手机没电时以备不时之需。</div>
                    </div>
                </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;width: 1120px;">
                <div class="hcell"><div class="hec_gn htable ">
                    <div class="hcell"><img :src="wisld_gn3"></div>
                    <div class="hcell" style="width: 342px;">
                        <div class="gnhead">智能照明</div>
                        <div class="gntxt">基于亮度均匀的调光、故障报警、智能单灯、集中控制开关灯。</div>
                    </div>
                </div></div>
                <div class="hcell"><div class="hec_gn htable ">
                    <div class="hcell"><img :src="wisld_gn4"></div>
                    <div class="hcell" style="width: 342px;">
                        <div class="gnhead">多媒体</div>
                        <div class="gntxt">广告播放、市政新闻、信息发布、实时公益信息插播（如儿童走失）等。</div>
                    </div>
                </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;width: 1120px;">
                <div class="hcell"><div class="hec_gn htable ">
                    <div class="hcell"><img :src="wisld_gn5"></div>
                    <div class="hcell" style="width: 342px;">
                        <div class="gnhead">环境监测</div>
                        <div class="gntxt">温度、湿度、PM2.5、噪音、风速、风向、风力、气压等。</div>
                    </div>
                </div></div>
                <div class="hcell"><div class="hec_gn htable ">
                    <div class="hcell"><img :src="wisld_gn6"></div>
                    <div class="hcell" style="width: 342px;">
                        <div class="gnhead">视频监控</div>
                        <div class="gntxt">安防监控、人脸识别、智能区域报警、车辆监控、停车收费、违章行驶等。</div>
                    </div>
                </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;width: 1120px;">
                <div class="hcell"><div class="hec_gn htable ">
                    <div class="hcell"><img :src="wisld_gn7"></div>
                    <div class="hcell" style="width: 342px;">
                        <div class="gnhead">通讯服务</div>
                        <div class="gntxt">路灯5G微基站、路灯wifi热点</div>
                    </div>
                </div></div>
                <div class="hcell"><div class="hec_gn htable ">
                    <div class="hcell" style="width: 342px;">
                    </div>
                </div></div>
            </div>
        </section>



        <section class="body1200" style="margin-top: 150px;">
            <div style="font-size: 42px;">
                相关案例
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="hec_al hec_al1"><div class="hec_albg1" ><div class="altxt1">
                    <div><img :src="address"></div>
                    <div>四川阿坝1MW扶贫光伏项目</div>
                </div> </div></div></div>
                <div class="hcell"><div class="hec_al hec_al2"><div class="hec_albg2" ><div class="altxt2">
                    <div><img :src="address"></div>
                    <div>四川理塘6MW扶贫光伏项目</div>
                </div> </div></div></div>
                <div class="hcell"><div class="hec_al hec_al3"> <div class="hec_albg3" ><div class="altxt3">
                    <div><img :src="address"></div>
                    <div>重庆巫溪整县扶贫光伏项目</div>
                </div> </div></div></div>
            </div>
        </section>

        <section style="height: 90px;"></section>
    </div>
</template>

<script>
    import myhead  from  '../components/myhead.vue'
    import cpblock_two  from  '../components/cpblock_two.vue'
    import cpblock_one  from  '../components/cpblock_one.vue'
    const param = {
        pageindex: 2,
        rows: 6,
        page: 1,
    }
    export default {
        name: "gflamp" ,
        components: {
            myhead,
            cpblock_two,
            cpblock_one
        },
        data() {
            return {
                param,
                wisld_fa1: require('../assets/wisld_fa1.jpg'),
                wisld_fa2: require('../assets/wisld_fa2.jpg'),
                wisld_fa3: require('../assets/wisld_fa3.jpg'),

                wisld_gn1: require('../assets/wisld_gn1.png'),
                wisld_gn2: require('../assets/wisld_gn2.png'),
                wisld_gn3: require('../assets/wisld_gn3.png'),
                wisld_gn4: require('../assets/wisld_gn4.png'),
                wisld_gn5: require('../assets/wisld_gn5.png'),
                wisld_gn6: require('../assets/wisld_gn6.png'),
                wisld_gn7: require('../assets/wisld_gn7.png'),


                address: require('../assets/address.png'),
            }
        },
        mounted() {

        },
        methods: {

        },
    }
</script>

<style scoped>
    .wisldtemplate{
        color: #202020;
        text-align: left;

    }
    .ghm{
        width: 152px;
        text-align: center;
    }
    .txtH16{
        font-size: 16px;
        color:rgba(20,20,20,0.8);
        line-height: 80px;
    }
    .txt18{
        font-size: 18px;
        color:rgba(20,20,20,0.8);
        line-height: 32px;
    }
    .txt42{
        font-size: 42px;
        color:rgba(20,20,20,1);
        line-height: 42px;
    }
    .txt60{
        font-size: 60px;
        color:rgba(20,20,20,1);
        line-height: 60px;
    }
    .bannerwisld{
        background-image: url('~assets/wisld_banner.jpg');
        height: 600px;
    }


    .wisld_jg{
        background-image: url('~assets/wisld_gnt.jpg');
        height: 678px;
    }

    .hec_al{
        width: 374px;
        height: 270px;
        border-radius: 10px;

    }

    .hec_al1{
        background-image: url('~assets/hec_al1.jpg');
    }
    .hec_al2{
        background-image: url('~assets/hec_al2.jpg');
    }
    .hec_al3{
        background-image: url('~assets/hec_al3.jpg');
    }

    .gnhead{
        font-size: 24px;
        font-weight: bold;
        margin-top: 8px;
        margin-left: 24px;
    }
    .gntxt{
        margin-top: 8px;
        margin-left: 24px;
        font-size: 16px;
        line-height: 24px;
        color: rgba(20,20,20,0.8);
    }

    .altxt1{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg1{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .altxt2{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg2{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .altxt3{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg3{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }
    .hec_al1:hover  .hec_albg1 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al1:hover .altxt1{
        display: inline;
    }

    .hec_al2:hover  .hec_albg2 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al2:hover .altxt2{
        display: inline;
    }
    .hec_al3:hover  .hec_albg3 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al3:hover .altxt3{
        display: inline;
    }

    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 28px;
    }
    .ghblockclick{
        cursor:pointer;
    }

    .ghblockclick:hover{
        color: #d7000f;
    }
</style>
